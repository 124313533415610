<div *ngIf="showFooter" class="w-full h-[500px] div-gradient lg:h-56">
    <div class="grid grid-cols-2 pl-12 pt-8 pb-8 gap-8 lg:flex lg:justify-between lg:pl-4 lg:mr-16 lg:ml-16">
        <div class="hidden lg:block">
                <img class="w-[131px] h-[64px] mb-8" src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FJoiiHome_logo.svg?alt=media&token=710aa9af-bfe9-43ee-8949-7e17150ce32a">
                <div class="w-[144px] h-[32px] flex justify-between">

                    <div (click)="navigate('facebook')" class="cursor-pointer">
                        <ng-icon name="ionLogoFacebook" color="white" class="" size="36px"></ng-icon>
                    </div>

                    <div (click)="navigate('instagram')" class="cursor-pointer">
                        <ng-icon name="ionLogoInstagram" color="white" class="" size="36px"></ng-icon>
                    </div>

                    <div (click)="navigate('twitter')" class="cursor-pointer">
                        <ng-icon name="ionLogoTwitter" color="white" class="" size="36px"></ng-icon>
                    </div>

                </div>
        </div>
        <div class="w-[109px] h-[103px] relative">
            <div class="left-0 top-7 absolute">
                <div class="bg-white w-[100px] h-[2px]"></div>
            </div>
            <div class="left-0 top-0 absolute text-white text-base font-medium font-['Poppins'] leading-normal tracking-tight">Useful Links</div>
            <div (click)="navigate('shop')"  class="left-0 top-[45px] absolute text-white text-sm font-medium font-['Poppins'] leading-[21px] tracking-tight cursor-pointer">Shop</div>
            <div (click)="navigate('health-clinic')" class="left-0 top-[82px] absolute text-white text-sm font-medium font-['Poppins'] leading-[21px] tracking-tight cursor-pointer">Health Clinic</div>
        </div>
        <div class="w-20 h-[66px] relative">
            <div class="left-0 top-7 absolute">
                <div class="bg-white w-[70px] h-[2px]"></div>
            </div>
            <div class="left-0 top-0 absolute text-white text-base font-medium font-['Poppins'] leading-normal tracking-tight">Support</div>
            <div (click)="navigate('contact-us')" class="left-0 top-[45px] absolute text-white text-sm font-medium font-['Poppins'] leading-[21px] tracking-tight cursor-pointer">Contact us</div>
        </div>
        <div class="w-36 h-[101px] relative">
            <div class="left-0 top-7 absolute">
                <div class="bg-white w-[48px] h-[2px]"></div>
            </div>
            <div class="left-0 top-0 absolute text-white text-base font-medium font-['Poppins'] leading-normal tracking-tight">Legal</div>
            <div (click)="navigate('privacy-policy')" class="left-0 top-[43px] absolute text-white text-sm font-medium font-['Poppins'] leading-[21px] tracking-tight cursor-pointer">Privacy policy</div>
            <div (click)="navigate('terms-conditions')" class="left-0 top-[80px] absolute text-white text-sm font-medium font-['Poppins'] leading-[21px] tracking-tight cursor-pointer">Terms & Conditions</div>
        </div>
        <div class="hidden grid grid-cols-1 pl-12 lg:block">
            <div class="mb-6 text-white text-lg font-medium font-['Poppins'] leading-[27px] tracking-tight flex justify-center">Download the app</div>
            <div class="flex">
                <img (click)="navigate('android-download')" class="w-[133px] h-10 mr-6 cursor-pointer" src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fgoogle-play-button.png?alt=media&token=d226d551-e049-4bf6-98ba-cc092b0831ea" />
                <img (click)="navigate('ios-download')" class="w-[133px] h-10 cursor-pointer" src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FApp-Store-button.png?alt=media&token=2caba153-68c3-4635-b266-7d25965237e4" />
            </div>
        </div>
    </div>
    <div class="grid grid-cols-1 pl-12 pb-8 lg:hidden">
        <div class="w-[144px] h-[32px] flex justify-between">

            <div (click)="navigate('facebook')" class="cursor-pointer">
                <ng-icon name="ionLogoFacebook" color="white" class="" size="36px"></ng-icon>
            </div>

            <div (click)="navigate('instagram')" class="cursor-pointer">
                <ng-icon name="ionLogoInstagram" color="white" class="" size="36px"></ng-icon>
            </div>

            <div (click)="navigate('twitter')" class="cursor-pointer">
                <ng-icon name="ionLogoTwitter" color="white" class="" size="36px"></ng-icon>
            </div>

        </div>
    </div>
    <div class="grid grid-cols-1 pl-12 lg:hidden">
        <div class="mb-6 text-white text-lg font-medium font-['Poppins'] leading-[27px] tracking-tight">Download the app</div>
        <div class="flex">
            <img (click)="navigate('android-download')" class="w-[133px] h-10 mr-6 cursor-pointer" src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fgoogle-play-button.png?alt=media&token=d226d551-e049-4bf6-98ba-cc092b0831ea" />
            <img (click)="navigate('ios-download')" class="w-[133px] h-10 cursor-pointer" src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FApp-Store-button.png?alt=media&token=2caba153-68c3-4635-b266-7d25965237e4" />
        </div>
    </div>
</div>