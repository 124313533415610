import { Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { IonIcon } from "@ionic/react";
import { filter } from "rxjs";
import { HideFooterPath } from "./joii-footer.interface";
import { NgIf } from "@angular/common";
import { NgIconsModule } from "@ng-icons/core";

@Component({
    selector: 'app-joii-footer',
    templateUrl: './joii-footer.component.html',
    styleUrls: ['./joii-footer.component.scss'],
    standalone: true,
    imports: [NgIf, NgIconsModule]
})
export class JoiiFooterComponent implements OnInit {
    @Input() hideFooterPaths!: HideFooterPath[];
    showFooter = true;

    links = [
        {
            name: 'shop',
            url: 'https://shop.joiipetcare.com/',
        },
        {
            name: 'health-clinic',
            url: ''
        },
        {
            name: 'contact-us',
            url: 'support/contact-us'
        },
        {
            name: 'privacy-policy',
            url: 'https://www.joiipetcare.com/privacy-policy'
        },
        {
            name: 'terms-conditions',
            url: 'https://www.joiipetcare.com/terms-of-service'
        },
        {
            name: 'android-download',
            url: 'https://www.joiipetcare.com/#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjUyNzkiLCJ0b2dnbGUiOmZhbHNlfQ%3D%3D'
        },
        {
            name: 'ios-download',
            url: 'https://www.joiipetcare.com/#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjUyNzkiLCJ0b2dnbGUiOmZhbHNlfQ%3D%3D'
        },
        {
            name: 'facebook',
            url: 'https://www.facebook.com/JoiiPetcare/'
        },
        {
            name: 'instagram',
            url: 'https://www.instagram.com/joiipetcare/?hl=en'
        },
        {
            name: 'twitter',
            url: 'https://twitter.com/PetJoii'
        }
    ];

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.router.events
        .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.showFooter = !this.shouldShowFooter(event.url);
        });
    }

    navigate(name: string) {
        window.location.href = this.links.find(l => l.name == name)?.url ?? '';
    }

    shouldShowFooter(url: string): boolean {
        return this.hideFooterPaths.some(hideFooterPath => {
            const wildcardIndex = hideFooterPath.path.indexOf('*');
            if (wildcardIndex === -1) {
              return url === hideFooterPath.path;
            } else {
              const pathStart = hideFooterPath.path.substring(0, wildcardIndex);
              return url.startsWith(pathStart);
            }
          });
    }
}