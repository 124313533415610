import { NgStyle } from '@angular/common';
import { Component, Input, } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  standalone: true,
  imports: [NgStyle]
})
export class ProgressBarComponent {
@Input() progressPercentage: string = "0%"
}
