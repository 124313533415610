import { Routes } from '@angular/router';
import { LoginGuard } from 'src/shared/guards/login.guard';

export const DashboardRoutes: Routes = [
  {
    path: "dashboard",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/dashboard-base/dashboard-base.component').then(m => m.DashboardBaseComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent),
        data: { endpoint: 'book' }
      },
      {
        path: 'appointments/:appointmentId',
        loadComponent: () => import('./pages/appointmentdetails/appointmentdetails.component').then(m => m.AppointmentDetailsComponent),
      },
      {
        path: 'appointments/:appointmentId/treatment',
        loadComponent: () => import('./pages/treatment-plan/treatment-plan.component').then(m => m.TreatmentPlanComponent),
        data: { endpoint: 'book' }
      },
    ]
  },
  {
    path: "changepet",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/changepet/changepet.component').then(m => m.ChangeActivePetComponent),

  },
  {
    path: "treatment/:appointmentId",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/treatment-plan/treatment-plan.component').then(m => m.TreatmentPlanComponent),

  }

];
