import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";
import { PolicyInactiveGuard } from "src/shared/guards/policyInactive.guard";
import { NoPetsGuard } from "./guards/pet/nopets.guard";

export const BookingRoutes: Routes = [
    {
        path: 'vetcall',
        canActivate: [LoginGuard, NoPetsGuard, PolicyInactiveGuard],
        loadComponent: () => import('./pages/vetcall/vetcall.component').then(m => m.VetcallComponent),
        loadChildren: () => import('./booking-vetcall-routes').then(m => m.BookingVetcallRoutes),
      }
]