import { Injectable } from '@angular/core';
import { ClientErrorMessage } from '../client/clienterror.interface';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    logClientError(error: ClientErrorMessage) {
        Sentry.captureException(error);
        // TODO - figure out how to get the trace id from the response
        // const traceId = response.headers.get('X-Encore-Trace-Id');
        // Sentry.withScope(scope => {
        //     const traceId = getCurrentHub().getScope()?.getTransaction()?.traceId;
        //     scope.setExtra('trace_id', traceId);
        //     Sentry.captureException(error);
        //   });
        console.error('An error occurred:', error.message);
        console.error('Error code:', error.code);
        console.error('Error details:', error.details ?? "No details provided");
    }
}
