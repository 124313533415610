import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { accountexternal, accountinternal, entities } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
  providedIn: 'root'
})

export class PetDataService {

  constructor(private client: ClientService) { }

  ListMyPets(): Observable<entities.ListPetsResponse> {
    return this.client.requestWithClient(client => client.accountexternal.ListPets("me"));
  }

  GetMyPet(id: string): Observable<entities.PetResponse> {
    return this.client.requestWithClient(client => client.accountexternal.GetPet("me", id));
  }

  ListBreeds(species_id: string): Observable<entities.GetBreedsResponse> {
    return this.client.requestWithClient(client => client.accountexternal.ListBreeds({ SpeciesDocID: species_id }));
  }

  ListSpecies(): Observable<accountexternal.GetSpeciesResponse> {
    return this.client.requestWithClient(client => client.accountexternal.ListSpecies());
  }

  CreatePet(dto: accountexternal.CreatePetRequest): Observable<entities.CreatePetResponse> {
    return this.client.requestWithClient(client => client.accountexternal.CreatePet("me", dto));
  }

  UpdatePet(id: string, dto: accountexternal.UpdatePetRequest): Observable<entities.UpdatePetResponse> {
    return this.client.requestWithClient(client => client.accountexternal.UpdatePet("me", id, dto));
  }

  DeletePet(user_doc_id: string, pet_doc_id: string, reason_id: string = "0"): Observable<void> {
    // reason_id defaults to 0 as giving a reason is optional currently.
    // there will never be a reason with the ID 0 in the database, so the backend code throws it away.
    return this.client.requestWithClient(client => client.accountexternal.DeletePet(user_doc_id, pet_doc_id, { ReasonID: reason_id }));
  }

  PatchPetPhoto(user_doc_id: string, pet_doc_id: string, params: accountexternal.PatchPetPhotoRequest): Observable<void> {
    return this.client.requestWithClient(client => client.accountexternal.PatchPetPhoto(user_doc_id, pet_doc_id, params))
  }

  ListPetDeletionReasons() {
    return this.client.requestWithClient(client => client.accountexternal.ListPetDeletionReasons())
  }
  GetPetDeletionReason(id: string) {
    return this.client.requestWithClient(client => client.accountexternal.GetPetDeletionReasonById(id))
  }
  CreatePetDeletionReason(request: accountinternal.CreatePetDeletionRequest) {
    return this.client.requestWithClient(client => client.accountinternal.CreatePetDeletionReason(request))
  }
  UpdatePetDeletionReason(id: string, request: accountinternal.UpdatePetDeletionRequest) {
    return this.client.requestWithClient(client => client.accountinternal.UpdatePetDeletionReason(id, request))
  }
  DeletePetDeletionReason(id: string) {
    return this.client.requestWithClient(client => client.accountinternal.DeletePetDeletionReason(id))
  }
}
