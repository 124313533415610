import { Routes } from "@angular/router";
import { NoPetsGuard } from "src/app/booking/guards/pet/nopets.guard";
import { LoginGuard } from "src/shared/guards/login.guard";
import { UnsavedChangesGuard } from "src/shared/guards/unsavedChangesGuard.guard";

export const SymptomCheckerRoutes: Routes = [
    {
      path: 'symptomchecker',
      canActivate: [LoginGuard, NoPetsGuard],
      loadComponent: () => import('./pages/sc-pet-selection/sc-pet-selection.component').then(m => m.ScPetSelectionComponent),
    },
    {
      path: 'symptomchecker/categories',
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/triage/triage.component').then(m => m.TriageComponent),
    },
    {
      path: 'recommendations',
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/sc-recommendations/sc-recommendations.component').then(m => m.SymptomCheckerRecommendationsComponent),
    },
    {
      path: 'symptomchecker/session/:docID',
      canActivate: [LoginGuard],
      canDeactivate: [UnsavedChangesGuard],
      loadComponent: () => import('./pages/web-sc-page/web-sc-page.component').then(m => m.WebScPageComponent),
    },
  ];