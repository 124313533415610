import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountDataService } from 'src/app/services/account.data.service/account.data.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanDeactivate<any> {
  constructor(private accountService: AccountDataService) {}

  canDeactivate(
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountService.GetMyUser().pipe(
      map((response: any) => {
        if (response.code && response.code == 'failed_precondition') {
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
