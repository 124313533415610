import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PetDataService } from 'src/app/services/pet.data.service/pet.data.service';

@Injectable({
  providedIn: 'root'
})
export class NoPetsGuard implements CanActivate {

  constructor(private petDataService: PetDataService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {

        this.petDataService.ListMyPets().subscribe((response) => {
            if (response.pets.length === 0) {
                this.router.navigate(['/dashboard']);
                resolve(false);
            } else {
                resolve(true);
            }
        })
    });
  }
}