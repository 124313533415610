import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { PetDataService } from "src/app/services/pet.data.service/pet.data.service";
import { AuthenticationService } from "../services/auth/auth_service";
import { Observable, catchError, map, of } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PolicyInactiveGuard implements CanActivate {

    constructor(
        private router: Router,
        private petsDataService: PetDataService,
        private authService: AuthenticationService,
    ) { }
  
    canActivate(): Observable<boolean> {
        const petId = localStorage.getItem('activePetId');
        const isInsured = this.authService.getActiveTenant()?.insurer;

        if (petId === null || petId === undefined) {
            return of(false);
        }

        return this.petsDataService.GetMyPet(petId).pipe(
            map(pet => {
                if (isInsured && !pet.policy_active) {
                    return false;
                }
                return true;
            }),
            catchError(() => of(false))
        );
    }
}