import { Component, OnInit, inject } from "@angular/core";
import { AccountDataService } from "src/app/services/account.data.service/account.data.service";
import { AuthenticationService } from "src/shared/services/auth/auth_service";
import { MixpanelService } from "src/shared/services/mix-panel.service/mix-panel.service";

@Component({
    selector: 'app-mix-panel',
    templateUrl: './mix-panel.component.html',
    standalone: true,
    imports: []
})
export class MixPanelComponent implements OnInit {
    authService = inject(AuthenticationService)
    ngOnInit(): void {
        this.authService.setMixPanelUser()
    }
}