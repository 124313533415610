import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Translation } from './translation.type';

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`./assets/i18n/${lang}.json`).pipe(
      map((translation) => {
        if (this.isTranslation(translation)) {
          return translation;
        } else {
          throw new Error(`${lang}.json translation file is not valid. You are missing properties.`);
        }
      })
    );
  }
  
   isTranslation(obj: any): obj is Translation {
    const dummyTranslation: Translation = {
      generic: {
        email_address: '',
        password: '',
        reset: '',
        new_to: '',
        already_registered_with: '',
        sign_in_with: '',
        or_continue_with: '',
        choose_another_provider: '',
        not_with: '',
        register_with: '',
        login: '',
        login_here: '',
        register: '',
        register_here: '',
        choose_another_provider_description: ''

      },
      app: {},
      pms: {},
      portal: {}
    };

    
    if (obj && typeof obj === 'object') {
      const keys = Object.keys(dummyTranslation);
      if(!keys.every(key => key in obj)){
        this.logMissingKeys(obj, keys)
        return false;
      }
      const generic_keys = Object.keys(dummyTranslation.generic);
      if(!generic_keys.every(key => key in obj['generic'])){
        this.logMissingKeys(obj, generic_keys, 'generic')
        return false;
      }
      const app_keys = Object.keys(dummyTranslation.app);
      if(!app_keys.every(key => key in obj['app'])){
        this.logMissingKeys(obj, app_keys, 'app')
        return false;
      }
      const portal_keys = Object.keys(dummyTranslation.portal);
      if(!portal_keys.every(key => key in obj['portal'])){
        this.logMissingKeys(obj, portal_keys, 'portal')
        return false;
      }
      const pms_keys = Object.keys(dummyTranslation.pms);
      if(!pms_keys.every(key => key in obj['pms'])){
        this.logMissingKeys(obj, pms_keys, 'pms')
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  logMissingKeys(obj: any, keys: string[], key_parent:string = ""): void {
    keys.forEach(key => {
      console.log("key: ", key);
      if (!(key in obj)) {
        console.log(`Missing key: ${key}` + (key_parent ? ` in ${key_parent}` : ''));
      }
    });
  }


  
}

