import { Injectable } from '@angular/core';
import { accountexternal, entities } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';
import { Observable, switchMap } from "rxjs";
import { SelectModel } from 'src/shared/components/forms/select/select_model';

@Injectable({
    providedIn: 'root'
})

export class AccountDataService {

    constructor(private client: ClientService) { }

    get phoneCodeOptions(): SelectModel[] {
        return [
            {
                "name": "Australia",
                "value": "AU",
                "data": "+61"
            },
            {
                "name": "Belgium",
                "value": "BE",
                "data": "+32"
            },
            {
                "name": "Brazil",
                "value": "BR",
                "data": "+55"
            },
            {
                "name": "Canada",
                "value": "CA",
                "data": "+1"
            },
            {
                "name": "China",
                "value": "CN",
                "data": "+86"
            },
            {
                "name": "Czech Republic",
                "value": "CZ",
                "data": "+420"
            },
            {
                "name": "Denmark",
                "value": "DK",
                "data": "+45"
            },
            {
                "name": "Finland",
                "value": "FI",
                "data": "+358"
            },
            {
                "name": "Germany",
                "value": "DE",
                "data": "+49"
            },
            {
                "name": "Spain",
                "value": "ES",
                "data": "+34"
            },
            {
                "name": "France",
                "value": "FR",
                "data": "+33"
            },
            {
                "name": "Greece",
                "value": "GR",
                "data": "+30"
            },
            {
                "name": "Hungary",
                "value": "HU",
                "data": "+36"
            },
            {
                "name": "Ireland",
                "value": "IE",
                "data": "+353"
            },
            {
                "name": "India",
                "value": "IN",
                "data": "+91"
            },
            {
                "name": "Italy",
                "value": "IT",
                "data": "+39"
            },
            {
                "name": "Japan",
                "value": "JP",
                "data": "+81"
            },
            {
                "name": "South Korea",
                "value": "KR",
                "data": "+82"
            },
            {
                "name": "Luxembourg",
                "value": "LU",
                "data": "+352"
            },
            {
                "name": "Mexico",
                "value": "MX",
                "data": "+52"
            },
            {
                "name": "Netherlands",
                "value": "NL",
                "data": "+31"
            },
            {
                "name": "New Zealand",
                "value": "NZ",
                "data": "+64"
            },
            {
                "name": "Norway",
                "value": "NO",
                "data": "+47"
            },
            {
                "name": "Poland",
                "value": "PL",
                "data": "+48"
            },
            {
                "name": "Portugal",
                "value": "PT",
                "data": "+351"
            },
            {
                "name": "Russia",
                "value": "RU",
                "data": "+7"
            },
            {
                "name": "Sweden",
                "value": "SE",
                "data": "+46"
            },
            {
                "name": "Switzerland",
                "value": "CH",
                "data": "+41"
            },
            {
                "name": "Turkey",
                "value": "TR",
                "data": "+90"
            },
            {
                "name": "South Africa",
                "value": "ZA",
                "data": "+27"
            },
            {
                "name": "UK",
                "value": "GB",
                "data": "+44"
            },
            {
                "name": "USA",
                "value": "US",
                "data": "+1"
            }
        ];
    }
    

    async GetUser(): Promise<accountexternal.GetUserResponse> {
        // @TODO: refactor usages to use Observables.
        const client = await this.client.getRequestClient();
        return await client.accountexternal.GetUser("my");
    }

    GetMyUser(): Observable<accountexternal.GetUserResponse> {
        return this.client.requestWithClient(client => client.accountexternal.GetUser("me"));
    }

    DeleteMyUser(): Observable<void> {
        return this.client.requestWithClient(client => client.accountexternal.DeleteUser("me"));
    }

    async CreateUser(dto: accountexternal.CreateUserRequest): Promise<accountexternal.CreateUserResponse> {
        const client = await this.client.getRequestClient();
        return await client.accountexternal.CreateUser(dto);
    }

    async UpdateUser(dto: accountexternal.UpdateUserRequest): Promise<accountexternal.UpdateUserResponse> {
        const client = await this.client.getRequestClient();
        return await client.accountexternal.UpdateUser("my", dto);
    }

    GetCurrentUser() {
        return this.client.client.pipe(switchMap(client => client.accountexternal.GetUser("me")));
    }
}
