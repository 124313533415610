import { Routes } from "@angular/router";

export const UserConsultationRoutes: Routes = [
    {
        path: "waitingroom/:id",
        loadComponent: () => import('./pages/waitingroom/waitingroom.component').then(m => m.WaitingRoomComponent),
      },
      {
        path: "consultation/:id",
        loadComponent: () => import('./pages/userconsultation/userconsultation.component').then(m => m.UserConsultationComponent),
      },
      {
        path: "finished/:id",
        loadComponent: () => import('./pages/finished/finished.component').then(m => m.UserConsultationFinishedComponent),
      }
]