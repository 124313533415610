import { Routes } from '@angular/router';
import { LoginGuard } from 'src/shared/guards/login.guard';

export const ChatRoutes: Routes = [
  {
    path: "joii-chat",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/chat/chat.component').then(m => m.ChatComponent),

  },
  {
    path: "joii-chat/choosePet",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/chat-pet-selection/chat-pet-selection.component').then(m => m.ChatPetSelectionComponent),

  }
];
